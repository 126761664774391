.hover-underline-text {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: white;

}

.hover-underline-text::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -2px;
  left: 0;
  background-color: #0BDA70;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out;
}

.hover-underline-text:hover::after {
  visibility: visible;
  transform: scaleX(1);
}
