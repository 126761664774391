.fade-in-wrapper {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .fade-in-wrapper.visible {
    opacity: 1;
    transform: translateY(0);
  }
  