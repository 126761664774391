.animated-text-container {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 100px 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text {
  display: inline-block;
  font-size: 60px;
  color: white;
  font-family: Arial, sans-serif;
}

.char {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.char.delay {
  animation-delay: 1.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.line {
  position: absolute;
  height: 1px;
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
}

.top-line {
  top: 0;
  animation: lineGrow 2s forwards;
}

.bottom-line {
  bottom: 0;
  animation: lineGrow 2s forwards;
}

@keyframes lineGrow {
  to {
    width: 100%;
  }
}
