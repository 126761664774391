.container {
  display: flex;
  width: 360px;
  border: 1px solid white;
  overflow: hidden;
  transition: transform 0.5s ease;
  background-color: black;
}

.red-rectangle {
  width: 60px;
  height: 200px;
  background-color: #0BDA70;
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.black-rectangle {
  width: 240px;
  height: 200px;
  background-color: black;
  position: relative;
  transition: transform 0.5s ease;
}

.container.hovered .red-rectangle,
.container.hovered .black-rectangle {
  transform: translateX(20%);
}
