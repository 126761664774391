.expandable-container {
    width: 100%;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    color: white;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    gap: 10px;
    cursor: pointer;
    color: white;
  }
  .header > span {
    color: white;
    font-size: 20px;
  }
  
  .icon {
    transition: transform 0.3s ease-in-out;
    color: #0BCF6B;
  }
  
  .icon.rotated {
    transform: rotate(90deg);
    color: #0BCF6B;
  }
  
  .content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    color: white;
  }
  
  .content.expanded {
    max-height: 600px; 
    opacity: 1;
    color: white;
  }
  
  .content p {
    margin-top: 20px;
    color: white;
    font-size: 14px;

  }
  